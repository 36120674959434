import axios from "@/utils/axios";

const get = async (id = "") => {
  try {
    const result = (await axios.get("/centro_custos", { params: { id } })).data;
    console.log(result);
    return result;
  } catch (error) {
    console.warn(error);
  }
};

const store = async (data) => {
  let result;
  await axios
    .post("/centro_custos", data)
    .then((res) => {
      result = res.data;
    })
    .catch((err) => {
      throw err.response;
    });

  return result;
};

const del = async (id) => {
  let result = await axios
    .delete(`/centro_custos/${id}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      if (err.response.data && result.data.error) {
        throw err.response.data.error.message;
      } else {
        throw new Error("Erro ao excluir categoria!");
      }
    });
  return result;
};

export default {
  get,
  store,
  del,
};
