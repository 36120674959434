<template>
  <div>
    <b-table
      :items="lista"
      :fields="campos"
      responsive="sm"
      :filter="filtro"
      :per-page="paginacao.registros_por_pagina"
      :current-page="paginacao.pagina_atual"
      striped
      :busy="loading"
    >
      <template
        #table-busy
        class="text-center"
      >
        <b-spinner />Carregando...
      </template>

      <template #cell(actions)="row">
       <div class="d-flex justify-content-end">
          <b-button
          size="sm"
          variant="warning"
          class="text-nowrap mr-3"
          @click="editar(row.item.id)"
        >
          <i class="fa fa-edit" /> Editar
        </b-button>
        <b-button
          size="sm"
          variant="primary"
          class="text-nowrap"
          @click="excluir(row.item.id)"
        >
          <i class="fa fa-trash" /> Excluir
        </b-button>
       </div>
        <!-- <b-button size="sm" variant="danger" @click="excluir(row.item)"
          ><i class="fa fa-trash"></i> Excluir</b-button
        > -->
      </template>
    </b-table>
    <b-pagination
      v-model="paginacao.pagina_atual"
      :total-rows="lista.length"
      :per-page="paginacao.registros_por_pagina"
    />
    <b-modal
      id="modal-custo"
      size="lg"
 
      no-fade
      content-class="modalDireito"
      hide-footer
    >
    <template #modal-title>
      <img src="../../assets/img/icones/menu/us_dollar_circled_40px_dark.png" alt=""> Cadastrar/Editar Centro de Custo
    </template>
      <CentrosDeCustoForm
        :id="id_edit"
        @close="closeModal"
        @salvou="carregar"
      />
    </b-modal>
  </div>
</template>

<script>
import CentrosDeCustoLib from "@/libs/CentrosDeCustoLib";
import CentrosDeCustoForm from "./CentrosDeCustoForm.vue";
export default {
  components: {
    CentrosDeCustoForm,
  },
  props: {
    filtro: String,
  },
  data() {
    return {
      loading: false,
      lista: [],
      id_edit: null,
      campos: [
        { key: "id", label: "Código", sortable: true },
        { key: "nome", label: "Nome",class:'text-center' },
        { key: "empresa", label: "Empresa",class:'text-center' },
        { key: "actions", label: "" },
      ],
      paginacao: {
        registros_por_pagina: 10,
        pagina_atual: 1,
      },
    };
  },
  mounted() {
    this.carregar();
  },
  methods: {
    async carregar() {
      this.loading = true;
      try {
        this.lista = await CentrosDeCustoLib.get();
      } catch (error) {
        console.warn(error);
        this.$swal.fire({
          title: "Erro ao carregar centros de custo!",
          icon: "error",
          toast: true,
          timer: 1500,
          position: "top-end",
          showConfirmButton: false,
        });
      } finally {
        this.loading = false;
      }
    },
    closeModal(){
        this.$bvModal.hide('modal-custo')
    },
    async novo() {
      //   this.limpa();
      this.id_edit = null
      this.$bvModal.show("modal-custo");
    },


    async editar(cod_custo) {
      this.$forceUpdate();
      await this.$nextTick();
    this.id_edit = cod_custo
      this.$bvModal.show("modal-custo");
    },

    async excluir(id){
        this.toastConfirmacao(async ()=>{
            try {
                const result = await CentrosDeCustoLib.del(id)
                console.log(result);
                await this.carregar()
            } catch (error) {
                console.error("Erro ao excluir categoria", error);
                this.showToast("error", error.message)
            }
        },()=>{

        },
        )
    },
  
  },
};
</script>

<style></style>
