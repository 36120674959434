<template>
  <div>
    <BarraCrud
      :botao-novo="true"
      :on-novo="novo"
      :on-filtrar="filtrar"
    />
    <CentrosDeCustoTable
      ref="centroCustoTable"
      :filtro="filtro"
    />
  </div>
</template>

<script>
import CentrosDeCustoTable from "@/components/CentrosDeCusto/CentrosDeCustoTable";
import BarraCrud from "@/components/common/BarraCrud";
export default {
  components: {
    BarraCrud,
    CentrosDeCustoTable
  },
  data() {
    return {
      filtro: "",
    };
  },
  methods: {
    novo() {
      this.$refs.centroCustoTable.novo();
    },
    filtrar(filtro) {
      this.filtro = filtro;
    },
  },
};
</script>

<style></style>
