<template>
  <b-form @submit.prevent.stop="salvar">
    <div class="row">
      <div class="col">
        <b-form-group>
          <label>Nome</label>
          <b-form-input

            v-model="centroCusto.nome"
            placeholder="Nome"
            :state="!!centroCusto.nome"
          />
        </b-form-group>
      </div>
      <div class="col">
        <b-form-group>
  
          <select-clinicas v-model="centroCusto.empresa_id"/>
        </b-form-group>
      </div>
    </div>
    <div class="bottom-actions" style="position: absolute;">
      <hr>
      <b-btn
        variant="primary"
        class="mr-3"
        type="submit"
      >
        <i class="fas fa-save" /> Salvar
      </b-btn>
      <b-btn
        variant="secondary"
        @click="$emit('close')"
      >
        <i class="fas fa-ban" /> Cancelar
      </b-btn>
    </div>
  </b-form>
</template>

<script>
import CentrosDeCustoLib from '../../libs/CentrosDeCustoLib'
import SelectClinicas from "../common/SelectClinicas.vue"

export default {
  components:{
    SelectClinicas
  },
  props: {
    id: { type: [Number, String] },
  },
  data(){
      return{
          centroCusto: {
              nome: '',
              empresa_id: null
          }
      }
  },
  methods:{
      async carregar(id){
         try {
              this.centroCusto = (await CentrosDeCustoLib.get(id))[0]
         } catch (error) {
             this.showToast('error', 'Erro ao editar centro de custo!')
         }
      },
      async salvar(){
          if (!this.centroCusto.nome) {
              this.showToast(
                  'error', 'Não é possível criar um centro de custo sem nome!', 3500
              )
              return
          }

          try {
              await CentrosDeCustoLib.store(this.centroCusto)
                this.showToast('success', 'Centro de Custo salvo com sucesso!')
                this.$emit('salvou')
                this.$emit('close')
          } catch (error) {
                this.showToast('error', 'Erro ao salvar centro de custo!')
          }
      }
  },
  async mounted(){
      if (this.id) {
         await this.carregar(this.id)
      }
  }
};
</script>

<style></style>
